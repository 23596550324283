<template>
  <div>
    <!--begin::Reset-->
    <div class="login-form">
        <b-form class="form" @submit.stop.prevent="onSubmit">
          
          <!--begin::Title-->
            <div class="pb-13 pt-lg-0 pt-5">
              <h3 class="font-weight-bolder text-dark font-size-h1 font-size-h1-lg">{{ $t("auth.title.reset_title") }}</h3>
              <p class="text-muted font-weight-bold font-size-h5">{{ $t("auth.title.reset_subtitle") }}</p>
            </div>
          <!--end::Title-->
      
          <b-form-group>
            <b-form-input
              class="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
              id="password"
              type="password"
              name="password"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="password-live-feedback"
              :placeholder="$t('auth.placeholder.new_password')"
            ></b-form-input>
            <b-form-invalid-feedback id="password-live-feedback">
              Zadejte minimálně 8 znaků.
            </b-form-invalid-feedback>
          </b-form-group>

        <b-form-group>
          <b-form-input
            class="form-control form-control-solid h-auto py-6 px-6 rounded-lg"
            id="password_repeat"
            type="password"
            name="password_repeat"
            v-model="$v.form.password_repeat.$model"
            :state="validateState('password_repeat')"
            aria-describedby="password-repeat-live-feedback"
            :placeholder="$t('auth.placeholder.new_password_repeat')"
          ></b-form-input>
          <b-form-invalid-feedback id="password-repeat-live-feedback">
            Heslo se neshoduje s výše uvedeným.
          </b-form-invalid-feedback>
        </b-form-group>

         <!--begin::Form group-->
        <div class="form-group d-flex flex-wrap pb-lg-0">
          <button 
            ref="kt_login_signin_submit"
            id="kt_login_forgot_submit"  
            class="btn btn-elevate btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
            >
            {{ $t("button.restore_password") }}
          </button>
         
          <router-link :to="{ name: 'login'}" class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3">
            {{ $t("button.login") }}
          </router-link>
        </div>
        <!--end::Form group-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Reset-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>

import { validationMixin } from "vuelidate";
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";

export default {
  name: "reset",
  mixins: [validationMixin],
  data() {
    return {
      // Remove this dummy login info
      form: {
        password: null,
        password_repeat: null,
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8)
      },
      password_repeat: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  },
   metaInfo () { 
      return { title: this.$t("auth.meta.reset")} 
  },
  methods: {
   validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
            return;
        }
        ApiService.apiPost("/auth/reset-password/" + this.$route.params.token, 
        { 
        'password': this.$v.form.password.$model,
        'password_confirmation': this.$v.form.password_repeat.$model
        });

    }
  },
  computed: {
   
  }
};
</script>
